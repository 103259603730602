<template>
    <div>

    </div>
    <div v-motion :initial="{
        opacity: 0,
    }" :enter="{

        opacity: 1,
        transition: {
            duration: 500,
            ease: 'easeIn',
        },
    }" class="flex flex-col bg-background xl:pt-4 mb-10 md:mb-10 xl:mb-0">
        <div class="xl:w-full md:w-full w-full h-auto  lg:mb-[0px] xl:mb-10">



            <div>
                <div class="text-center md:mb-10">
                    <div class=" xl:mt-0  text-center">
                        <h1
                            class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
                            ចក្ខុវិស័យ
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-4"></div>

                    </div>
                </div>
                <div class="w-[95%] lg:w-[55%] xl:w-[700px] md:w-[90%] mx-auto xl:px-5 mt-5 xl:my-0 ">

                    <div
                        class="flex md:flex gap-4 md:gap-0  items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">
                        <div class="w-[500px]">
                            <div class="">
                                <img class="w-[100px] h-[100px] md:w-[250px] md:h-[250px] lg:w-[200px] lg:h-[200px] xl:w-[250px] xl:h-[250px]"
                                    src="../assets/about/Vision.png" alt="Product Image" />
                            </div>
                        </div>
                        <div class="md:w-full">
                            <h5
                                class="text-justify md:text-[18px] lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">
                                យើងជឿជាក់ថាកក្កាដ៏សំខាន់មួយជួយធ្វើឱ្យអាជីវកម្ម និង ប្រទេសជាតិរីកចម្រើនលឿនគឺ
                                ភាពជឿនលឿននៃបច្ចេកវិទ្យានិងឌីជីថលម៉ាស្ទ័រអាយធី​ស៊ីស្ទឹម នឹងក្លាយជា ក្រុមហ៊ុនបច្ចេកវិទ្យា
                                ឈានមុខគេបង្កើតមោតនភាពជូនប្រជាជនកម្ពុជា។
                            </h5>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div class=" text-center md:mb-10">
                    <div class="lg:mt-5 xl:mt-0  text-center">
                        <h1
                            class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
                            បេសកម្ម
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-1"></div>

                    </div>
                </div>
                <div class="w-[95%] lg:w-[55%] xl:w-[700px] md:w-[90%] mx-auto xl:px-5 mt-5 xl:my-0 ">

                    <div
                        class="flex md:flex gap-4 md:gap-0  items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">

                        <div class=" md:w-full">
                            <h5
                                class=" hidden md:block text-justify md:text-[18px] lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60  font-NotoSansKhmer font-[600]">
                                អភិវឌ្ឍន៍ប្រព័ន្ធ បច្ចេកវិទ្យា និង ឌីជីថលឱ្យជឿនលឿន ដើម្បី ជួយប្រជាជនកម្ពុជា
                                បំពេញការងារកាន់តែងាយស្រួល។ </h5>

                            <h5
                                class="text-justify md:hidden md:text-[18px] lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">
                                អភិវឌ្ឍន៍ប្រព័ន្ធបច្ចេកវិទ្យា និង ឌីជីថល ឱ្យជឿនលឿន ដើម្បី ជួយប្រជាជន
                                កម្ពុជាបំពេញការងារកាន់តែងាយស្រួល។ </h5>
                        </div>

                        <div class="w-[250px] md:w-[500px] flex justify-end">
                            <div class="">
                                <img class="w-[100px] h-[100px] md:w-[250px] md:h-[250px] lg:w-[200px] lg:h-[200px] xl:w-[250px] xl:h-[250px]"
                                    src="../assets/about/Mision.png" alt="Product Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div>
                <div class="text-center md:mb-10">
                    <div class="lg:mt-5 xl:mt-0  text-center">
                        <h1
                            class="text-center text-primery1 xl:text-3xl xl:my-0 md:text-3xl md:mt-[25px] mt-5 lg:text-2xl  text-2xl xl:mt-10 font-NotoSansKhmer">
                            គុណតម្លៃ
                        </h1>
                        <div class="border-b-[4px] border-primery1 w-16 lg:w-16 xl:w-20 mx-auto mt-2"></div>

                    </div>
                </div>
                <div class="w-[95%] lg:w-[55%] xl:w-[700px] md:w-[90%] mx-auto xl:px-5 mt-5 xl:my-0 space-y-3 ">

                    <div
                        class="flex md:flex  md:gap-5 gap-5   items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">
                        <div class="w-[150px] md:w-[250px] sm:flex md:justify-center ">
                            <div class="">
                                <img class="w-[100px] h-[100px]  md:w-[150px] md:h-[150px] lg:w-[130px] lg:h-[130px] xl:w-[160px] xl:h-[160px]"
                                    src="../assets/about/Excellent.png" alt="Product Image" />
                            </div>
                        </div>
                        <div class="w-full">
                            <div
                                class="text-justify md:text-[18px]  lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">

                                <div class="space-y-2">
                                    <span class="font-bold">1. ឧត្តមភាព (Excellence)</span>
                                    <p class="text-[13px] md:text-[16px] lg:text-[16px] xl:text-[16px]">
                                        យើងប្រកាន់ខ្ជាប់នូវការ អនុវត្តនីតិវិធី ប្រតិបត្តការស្តង់ដារ ដើម្បី សម្រេចឱ្យបាន
                                        នូវភាពល្អឯកលើ គុណភាព, ប្រសិទ្ធភាព, សុវត្ថិភាព, ភាពស្តង់ដារ និងសេចក្តីថ្លៃថ្នូ
                                        សម្រាប់អតិថិជននិងគ្រប់អ្នកពាក់ព័ន្ធរបស់យើង។</p>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div
                        class="flex md:flex  md:gap-5 gap-5   items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">
                        <div class="w-[150px] md:w-[250px] sm:flex md:justify-center ">
                            <div class="">
                                <img class="w-[100px] h-[100px]  md:w-[150px] md:h-[150px] lg:w-[130px] lg:h-[130px] xl:w-[160px] xl:h-[160px]"
                                    src="../assets/about/CustomerSatisfaction.png" alt="Product Image" />
                            </div>
                        </div>
                        <div class="w-full">
                            <div
                                class=" md:text-[18px]  lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">

                                <div class="space-y-2">
                                    <span class="font-bold">2. ភាពពេញចិត្តរបស់អតិថិជន (CustomerSatisfaction)</span>
                                    <p class="text-[13px] text-justify md:text-[16px] lg:text-[16px] xl:text-[16px]">
                                        យើងបេ្តជ្ញាផ្តល់នូវភាពពេញចិត្តខ្ពស់បំផុតសម្រាប់អតិថិជន
                                        ទាំងគុណភាពផលិតផល និងសេវាកម្មលឿនរហ័ស ប្រកបដោយទំនុកចិត្ត និងភាពជឿជាក់។
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div
                        class="flex md:flex  md:gap-5 gap-5   items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">
                        <div class="w-[150px] md:w-[250px] sm:flex md:justify-center ">
                            <div class="">
                                <img class="w-[100px] h-[100px]  md:w-[150px] md:h-[150px] lg:w-[130px] lg:h-[130px] xl:w-[160px] xl:h-[160px]"
                                    src="../assets/about/Innovation.png" alt="Product Image" />
                            </div>
                        </div>
                        <div class="w-full">
                            <div
                                class=" md:text-[18px]  lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">

                                <div class="space-y-2">
                                    <span class="font-bold">3. ច្នៃប្រឌិតបង្កើតថ្មី (Innovation)</span>
                                    <p class="text-[13px] text-justify md:text-[16px] lg:text-[16px] xl:text-[16px]">
                                        យើងបន្តការច្នៃប្រឌិត និងបង្កើតអ្វីដែលថ្មី
                                        ដើម្បីធានាឱ្យបាននូវនិរត្តន៍ភាពគុណភាពនៃផលិតផលនិង សេវាកម្ម
                                        និងបំពេញរាល់គ្រប់តម្រូវការដល់អតិថិជនរបស់យើង។
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div
                        class="flex md:flex  md:gap-5 gap-5   items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">
                        <div class="w-[150px] md:w-[250px] sm:flex md:justify-center ">
                            <div class="">
                                <img class="w-[100px] h-[100px]  md:w-[150px] md:h-[150px] lg:w-[130px] lg:h-[130px] xl:w-[160px] xl:h-[160px]"
                                    src="../assets/about/Integrity.png" alt="Product Image" />
                            </div>
                        </div>
                        <div class="w-full">
                            <div
                                class=" md:text-[18px]  lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">

                                <div class="space-y-2">
                                    <span class="font-bold">4. ក្រមសីលធម៌និងវិជ្ជាជីវៈ (Integrity)</span>
                                    <p class="text-[13px] text-justify md:text-[16px] lg:text-[16px] xl:text-[16px]">
                                        យើងបំពេញការងារប្រកបដោយក្រមសីលធម៌ មានវិជ្ជាជីវៈ ស្មោះត្រង់
                                        និងទទួលខុសត្រូវ។
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div
                        class="flex md:flex  md:gap-5 gap-5   items-center border p-3 md:p-4 lg:p-5 xl:p-3 rounded-md space-y-2 md:space-y-0">
                        <div class="w-[150px] md:w-[250px] sm:flex md:justify-center ">
                            <div class="">
                                <img class="w-[100px] h-[100px]  md:w-[150px] md:h-[150px] lg:w-[130px] lg:h-[130px] xl:w-[160px] xl:h-[160px]"
                                    src="../assets/about/Collaboration.png" alt="Product Image" />
                            </div>
                        </div>
                        <div class="w-full">
                            <div
                                class=" md:text-[18px]  lg:text-[16px] xl:text-[18px]  text-[15px] text-black/60 inline-block font-NotoSansKhmer font-[600]">

                                
                                <div class="space-y-2">
                                    <span class="font-bold">5. ការសហការ (Collaboration)</span>
                                    <p class="text-[13px] text-justify md:text-[16px] lg:text-[16px] xl:text-[16px]">
                                        យើងសហការគ្នា តាមរយៈ ការពង្រឹង ទំនាក់ទំនង ប្រកបដោយប្រសិទ្ធិភាព
                                        និងរក្សាភាពជាដៃគូរដ៏ល្អនិងយូរអង្វែង
                                        ជាមួយក្រុមការងារ, ដៃគូរ, និង អតិថិជន របស់យើង ដើម្បីភាពរីកចម្រើនរួមគ្នា ។
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import { useRouter } from "vue-router";
import getCollection from "@/composible/getCollections.js";


import Navbar from "./Navbar.vue";
export default {
    components: {
        Navbar
    },
    setup() {


        const { documents: category } = getCollection('categories')


        const router = useRouter();

        return { category };
    },
};
</script>

<style scoped></style>