<template>
    <div class=" w-full h-screen fixed top-0 right-0 bg-black/20 z-[20]">
        <div v-motion-slide-top
            class="w-[90%] md:w-[80%] lg:w-[50%] xl:w-[80%]  mx-auto p-5  bg-white mt-[4rem] md:mt-[1rem]">
            <div class="w-full mx-auto flex items-center justify-between ">
                <h1 class="text-primery1 text-heading3">
                    {{ datatoedit ? "កែប្រែព័ត៌មានលម្អិត" : "បន្ថែមថ្មី" }}
                </h1>
                <button @click="handleClose"
                    class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-8 h-8 text-primery1">
                        <path
                            d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                    </svg>
                </button>
            </div>
            <div class="h-[820px] overflow-y-auto">
                <form @submit.prevent="handleSubmitProductDetail" class="w-full space-y-2 items-center">
                    <div class="grid grid-cols-2 gap-3">

                        <!-- section 1  -->
                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3">
                                <legend class="text-md">ណែនាំពីផលិតផល</legend>
                                <div class="w-full flex gap-2">
                                    <div v-if="!datatoedit" class="w-full space-y-2">
                                        <label class="text-body text-textbody">ជ្រើសរើសផលិតផល</label>
                                        <select required v-model="productName"
                                            class="border-[1px] w-full p-2 hover:border-primery1">
                                            <option value="">--ជ្រើសរើស--</option>
                                            <option v-for="pro in product" :key="pro.id" :value="pro.id">{{ pro.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="space-y-2">
                                    <label class="text-body text-textbody">វីដេអូបង្ហាញ</label>
                                    <div>

                                        <div class="w-full space-y-2">
                                            <input v-model="introductVideoLink" id="introductVideoLink" type="text"
                                                class="border p-2 w-full hover:border-primery1"
                                                placeholder="វីដេអូអំពីផលិតផល" />
                                        </div>

                                        <!-- Video Preview -->
                                        <div v-if="isValidIntroDetailLink" class="mt-4">
                                            <h4>វីដេអូពិពណ៌នា:</h4>

                                            <video v-if="isDirectIntroDetailLink" :src="introductVideoLink" controls
                                                class="w-full max-w-sm"></video>

                                            <iframe v-else :src="youtubeEmbedIntroLink" frameborder="0"
                                                allow="autoplay; encrypted-media" allowfullscreen
                                                class="w-full max-w-md aspect-video"></iframe>
                                        </div>
                                        <p v-else-if="introductVideoLink" class="text-red-500 mt-2">
                                            សូមបញ្ចូលលីងវីដេអូឲបានត្រឹមត្រូវ
                                        </p>

                                    </div>
                                </div>


                                <div class="my-3 relative">
                                    <div class="w-full space-y-2">
                                        <label class="text-body text-textbody py-2"> រូបភាព </label>
                                        <div
                                            class="cursor-pointer h-[120px] w-[170px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                                            <div v-if="!preViewIntroFile"
                                                class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer ">
                                                <img :src="selectIntroFile || 'https://fakeimg.pl/170x120/'"
                                                    class="w-full h-full" alt="">
                                            </div>
                                            <div v-else class="h-[200px] w-full overflow-auto overflow-x-auto">
                                                <img :src="preViewIntroFile" alt="Selected Image"
                                                    class="pt-10 items-center object-contain" />
                                            </div>

                                            <img v-if="preViewIntroFile" :src="productImage" alt="">

                                            <input @change="handleIntroFile" type="file"
                                                class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                                        </div>
                                    </div>

                                    <div class="absolute top-8 left-0">
                                        <button type="button" @click="handleRemoveIntroImage"
                                            class="bg-red-500 p-1 text-white hover:bg-red-600 mt-1"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="lucide lucide-trash-2">
                                                <path d="M3 6h18" />
                                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                                <line x1="10" x2="10" y1="11" y2="17" />
                                                <line x1="14" x2="14" y1="11" y2="17" />
                                            </svg></button>
                                    </div>
                                </div>


                                <div class="w-full space-y-2">
                                    <label class="text-body text-textbody">មាតិការ</label>
                                    <ckeditor :editor="editor" v-model="introductContent" :config="editorConfig" />
                                </div>
                            </fieldset>
                        </div>

                        <!-- section 2 -->

                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3">
                                <legend class="text-md">ព័ត៌មានលម្អិតអំពីផលិតផល</legend>


                                <div class="w-full space-y-2">
                                    <label class="text-body text-textbody">ពិពណ៌នា</label>
                                    <ckeditor :editor="editor" v-model="productBriefDescription"
                                        :config="editorConfig" />
                                </div>

                                <div class="w-full space-y-2 relative">
                                    <label class="text-body text-textbody py-2"> រូបភាព </label>
                                    <div
                                        class="cursor-pointer h-[120px] w-[170px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                                        <div v-if="!preViewProductBriefFile"
                                            class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer ">
                                            <img :src="selectProductBriefFile || 'https://fakeimg.pl/170x120/'"
                                                class="w-full h-full" alt="">
                                        </div>
                                        <div v-else class="h-[200px] w-full overflow-auto overflow-x-auto">
                                            <img :src="preViewProductBriefFile" alt="Selected Image"
                                                class="pt-10 items-center object-contain" />
                                        </div>

                                        <img v-if="preViewProductBriefFile" :src="productImage" alt="">

                                        <input @change="handleProductBriefImage" type="file"
                                            class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                                    </div>


                                    <div class="absolute top-6 left-0">
                                        <button type="button" @click="handleRemoveProductBriefImage"
                                            class="bg-red-500 p-1 text-white hover:bg-red-600 mt-1"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="lucide lucide-trash-2">
                                                <path d="M3 6h18" />
                                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                                                <line x1="10" x2="10" y1="11" y2="17" />
                                                <line x1="14" x2="14" y1="11" y2="17" />
                                            </svg></button>
                                    </div>
                                </div>

                            </fieldset>
                        </div>

                        <!-- section 3 -->
                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3 relative">
                                <legend class="text-md">លក្ខណៈពិសេសអំពីផលិតផល</legend>

                                <div class="w-full space-y-2 flex justify-center">
                                    <div>
                                        <label class="text-body text-textbody py-2"> រូបភាព </label>
                                        <div
                                            class="cursor-pointer h-[120px] w-[170px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                                            <div
                                                class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer ">
                                                <img src="https://fakeimg.pl/170x120/" class="w-full h-full" alt="">
                                            </div>


                                            <input @change="handleKeyFeatureImage" type="file"
                                                class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                                        </div>

                                    </div>
                                </div>

                                <div v-if="selectKeyFeatureBenefitFile?.length > 0"
                                    class="grid grid-cols-5 gap-4 mt-5 relative">
                                    <div v-for="(feature, index) in selectKeyFeatureBenefitFile" :key="index">
                                        <div>
                                            <img :src="feature.image" class="w-24 h-24 object-contain">
                                        </div>

                                        <div class="absolute top-0">
                                            <button type="button" @click="handleRemoveKeyFeature(index)"
                                                class="bg-red-500 p-1 rounded-full m-0.5 text-white hover:bg-red-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-x">
                                                    <path d="M18 6 6 18" />
                                                    <path d="m6 6 12 12" />
                                                </svg>
                                            </button>

                                            <button type="button" v-if="datatoedit"
                                                @click="handleAddEditFeatureImage(index)"
                                                class="bg-blue-500 p-1 rounded-full m-0.5 text-white hover:bg-blue-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-pencil">
                                                    <path
                                                        d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                                                    <path d="m15 5 4 4" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <div class="">
                                    <div class="space-y-2">
                                        <label class="text-body text-textbody">ចំណងជើង: *</label>
                                        <input type="text" v-model="keyFeatureBenefitTitle" placeholder="ចំណងជើង"
                                            class="input_form w-full">
                                    </div>

                                </div>
                                <div class="flex items-center gap-3">
                                    <div class="w-full space-y-2">
                                        <label class="text-body text-textbody">មុខងារ</label>
                                        <ckeditor :editor="editor" v-model="keyFeatureBenefitDescription"
                                            :config="editorConfig" />
                                    </div>
                                    <div class="-mt-5">
                                        <button @click="addDescriptionRow" type="button"
                                            class="bg-indigo-600 p-2 px-4 text-white hover:bg-indigo-500">+</button>
                                    </div>
                                </div>

                                <!-- array of key feature and benifit here -->
                                <div v-if="keyFeatureBenefitList?.length > 0" class="mt-4 space-y-2">
                                    <div v-for="(item, index) in keyFeatureBenefitList" :key="index"
                                        class="flex items-center gap-2 justify-between">
                                        <div class="flex gap-2 items-center">
                                            <span>{{ index + 1 }}.</span>
                                            <div>
                                                <p><strong>ចំណងជើង:</strong> {{ item.title }}</p>
                                                <div><strong>ពិពណ៌នា:</strong>
                                                    <p v-html="item.description"></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="space-x-2">
                                            <button type="button" @click="removeDescriptionRow(index)"
                                                class="bg-red-600 text-white py-2 px-2 hover:bg-red-500">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-x">
                                                    <path d="M18 6 6 18" />
                                                    <path d="m6 6 12 12" />
                                                </svg>
                                            </button>

                                            <button v-if="datatoedit" type="button" @click="handleEditContent(index)"
                                                class="bg-blue-600 text-white py-2 px-2 hover:bg-blue-500">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="lucide lucide-pencil">
                                                    <path
                                                        d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                                                    <path d="m15 5 4 4" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div>

                                </div>
                            </fieldset>
                        </div>


                        <!-- section 4 -->
                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3">
                                <legend class="text-md">សាកសមសម្រាប់អាជីវកម្ម</legend>

                                <div class="w-full space-y-2">
                                    <div>
                                        <label class="text-body text-textbody py-2"> រូបភាពអាជីវកម្ម</label>
                                        <div
                                            class="cursor-pointer h-[120px] w-[170px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                                            <div
                                                class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer ">
                                                <img src="https://fakeimg.pl/170x120/" class="w-full h-full" alt="">
                                            </div>


                                            <input @change="handleTargetBusiness" type="file"
                                                class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                                        </div>

                                        <div v-if="selectTargetBusinessFeature?.length > 0"
                                            class="flex gap-4 mt-5 relative">
                                            <div v-for="(feature, index) in selectTargetBusinessFeature" :key="index">
                                                <div>
                                                    <img :src="feature.image" class="w-20 h-20">
                                                </div>

                                                <div class="absolute top-0">
                                                    <button type="button" @click="handleRemoveTargetBusiness(index)"
                                                        class="bg-red-500 p-1 rounded-full m-0.5 text-white hover:bg-red-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="lucide lucide-x">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </button>

                                                    <button type="button" v-if="datatoedit"
                                                        @click="handleEditTargetBussiness(index)"
                                                        class="bg-blue-500 p-1 rounded-full m-0.5 text-white hover:bg-blue-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="lucide lucide-pencil">
                                                            <path
                                                                d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                                                            <path d="m15 5 4 4" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </fieldset>

                            <fieldset class="border border-solid border-gray-300 p-3 mt-2">
                                <legend class="text-md">វីដេអូបង្ហាញពីអាជីវកម្ម</legend>

                                <div class="w-full space-y-2">
                                    <div>
                                        <label class="text-body text-textbody py-2"> វីដេអូសម្រាប់អាជីវកម្ម</label>
                                        <div class="w-full space-y-2">
                                            <input v-model="productDetailLink" id="productDetailLink" type="text"
                                                class="border p-2 w-full hover:border-primery1"
                                                placeholder="វីដេអូអំពីផលិតផល" />
                                        </div>

                                        <!-- Video Preview -->
                                        <div v-if="isValidproductDetailLink" class="mt-4">
                                            <h4>វីដេអូពិពណ៌នា:</h4>

                                            <video v-if="isDirectproductDetailLink" :src="productDetailLink" controls
                                                class="w-full max-w-sm"></video>

                                            <iframe v-else :src="youtubeEmbedLink" frameborder="0"
                                                allow="autoplay; encrypted-media" allowfullscreen
                                                class="w-full max-w-md aspect-video"></iframe>
                                        </div>
                                        <p v-else-if="productDetailLink" class="text-red-500 mt-2">
                                            សូមបញ្ចូលលីងវីដេអូឲបានត្រឹមត្រូវ
                                        </p>
                                    </div>
                                </div>


                            </fieldset>
                        </div>

                        <!-- section 5 -->

                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3 relative">
                                <legend class="text-md">ព័ត៌មានលម្អិតផ្ទាំង</legend>

                                <div>
                                    <div>
                                        <div class="w-full space-y-2">
                                            <div>
                                                <div class="space-y-2">
                                                    <label class="text-body text-textbody">លក្ខណៈពិសេស: *</label>

                                                    <select required v-model="specificationTitle" class="input_form">
                                                        <option value="">ជ្រើសរើស</option>
                                                        <option value="លក្ខណៈពិសេស">លក្ខណៈពិសេស</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="flex items-center gap-3">
                                            <div class="w-full space-y-2">
                                                <label class="text-body text-textbody">ពិពណ៌នា</label>
                                                <ckeditor :editor="editor" v-model="specificationDescription"
                                                    :config="editorConfig" />
                                            </div>
                                            <div class="-mt-5">
                                                <button @click="addSpacificationList" type="button"
                                                    class="bg-indigo-600 p-2 px-4 text-white hover:bg-indigo-500">+</button>
                                            </div>
                                        </div>


                                    </div>

                                    <div v-if="specificationList?.length > 0" class="mt-4 space-y-2">
                                        <div v-for="(description, index) in specificationList" :key="index"
                                            class="flex items-center gap-2 justify-between">
                                            <div class="flex gap-2 items-center">
                                                <span>{{ index + 1 }}.</span>
                                                <p v-html="description"></p>
                                            </div>


                                            <div class="space-x-2">
                                                <button type="button" @click="removeSpacificationList(index)"
                                                    class="bg-red-600 text-white py-2 px-2 hover:bg-red-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="lucide lucide-x">
                                                        <path d="M18 6 6 18" />
                                                        <path d="m6 6 12 12" />
                                                    </svg>
                                                </button>

                                                <button v-if="datatoedit" type="button"
                                                    @click="handleEditSpacification(index)"
                                                    class="bg-blue-600 text-white py-2 px-2 hover:bg-blue-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="lucide lucide-pencil">
                                                        <path
                                                            d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                                                        <path d="m15 5 4 4" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>


                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3 relative">
                                <legend class="text-md">ព័ត៌មានលម្អិតផ្ទាំង</legend>

                                <div>
                                    <div>
                                        <div class="w-full space-y-2">
                                            <div>
                                                <div class="space-y-2">
                                                    <label class="text-body text-textbody">ជម្រើសកញ្ចប់មុខងារ: *</label>

                                                    <select required v-model="packageTitle" class="input_form">
                                                        <option value="">ជ្រើសរើស</option>
                                                        <option value="ជម្រើសកញ្ចប់មុខងារ">ជម្រើសកញ្ចប់មុខងារ</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="flex items-center gap-3">
                                            <div class="w-full space-y-2">
                                                <label class="text-body text-textbody">មុខងារ</label>
                                                <ckeditor :editor="editor" v-model="packageDescription"
                                                    :config="editorConfig" />
                                            </div>
                                            <div class="-mt-5">
                                                <button @click="addPackageList" type="button"
                                                    class="bg-indigo-600 p-2 px-4 text-white hover:bg-indigo-500">+</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="packageList?.length > 0" class="mt-4 space-y-2">
                                        <div v-for="(description, index) in packageList" :key="index"
                                            class="flex items-center gap-2 justify-between">
                                            <div class="flex gap-2 items-center">
                                                <span>{{ index + 1 }}.</span>
                                                <p v-html="description"></p>
                                            </div>


                                            <div class="space-x-2">
                                                <button type="button" @click="removePackageList(index)"
                                                    class="bg-red-600 text-white py-2 px-2 hover:bg-red-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="lucide lucide-x">
                                                        <path d="M18 6 6 18" />
                                                        <path d="m6 6 12 12" />
                                                    </svg>
                                                </button>

                                                <button v-if="datatoedit" type="button"
                                                    @click="handleEditPackage(index)"
                                                    class="bg-blue-600 text-white py-2 px-2 hover:bg-blue-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="lucide lucide-pencil">
                                                        <path
                                                            d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                                                        <path d="m15 5 4 4" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>
                        </div>


                        <div>
                            <fieldset class="border border-solid border-gray-300 p-3">
                                <legend class="text-md">ព័ត៌មានលម្អិតផ្ទាំង</legend>

                                <div class="w-full space-y-2">
                                    <div>
                                        <div class="space-y-2">
                                            <label class="text-body text-textbody">ជម្រើសកញ្ចប់ភ្ជាប់ជាមួយឧបករណ៍:
                                                *</label>

                                            <select required v-model="hardwareTitle" class="input_form">
                                                <option value="">ជ្រើសរើស</option>
                                                <option value="ជម្រើសកញ្ចប់ភ្ជាប់ជាមួយឧបករណ៍">
                                                    ជម្រើសកញ្ចប់ភ្ជាប់ជាមួយឧបករណ៍</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full space-y-2">
                                    <div>
                                        <label class="text-body text-textbody py-2"> រូបភាព</label>
                                        <div
                                            class="cursor-pointer h-[120px] w-[170px] border-[1px] hover:border-primery1 border-dashed flex items-center justify-center relative overflow-auto">
                                            <div
                                                class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointer ">
                                                <img src="https://fakeimg.pl/170x120/" class="w-full h-full" alt="">
                                            </div>


                                            <input @change="handleSelectHardwareFile" type="file"
                                                class="opacity-0 absolute h-full w-[90%] cursor-pointer" />
                                        </div>

                                        <div v-if="selectHardwareSystemFeature?.length > 0"
                                            class="flex gap-4 mt-5 relative">
                                            <div v-for="(feature, index) in selectHardwareSystemFeature" :key="index">
                                                <div>
                                                    <img :src="feature.image" class="w-20 h-20">
                                                </div>

                                                <div class="absolute top-0">

                                                    <button type="button" @click="handleRemoveHardwareSystem(index)"
                                                        class="bg-red-500 p-1 rounded-full m-0.5 text-white hover:bg-red-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="lucide lucide-x">
                                                            <path d="M18 6 6 18" />
                                                            <path d="m6 6 12 12" />
                                                        </svg>
                                                    </button>

                                                    <button type="button" v-if="datatoedit"
                                                        @click="handleAddEditHardwareImage(index)"
                                                        class="bg-blue-500 p-1 rounded-full m-0.5 text-white hover:bg-blue-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="lucide lucide-pencil">
                                                            <path
                                                                d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
                                                            <path d="m15 5 4 4" />
                                                        </svg>
                                                    </button>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>


                            </fieldset>
                        </div>


                    </div>

                    <!-- button submit -->
                    <div v-if="!loading" class="w-auto flex justify-end mx-auto">
                        <button
                            class="p-2 px-8 bg-primery1 hover:bg-indigo-700 transition-all duration-300 ease-in-out text-white mt-5">
                            {{ datatoedit ? "កែប្រែ" : "រក្សាទុក" }}
                        </button>
                    </div>
                    <div v-else class="w-auto flex justify-end mx-auto">
                        <button type="submit"
                            class="p-2 px-4 space-x-2 bg-primery1 flex items-center justify-center text-white mt-5">
                            <svg aria-hidden="true"
                                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.59C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor" />
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill" />
                            </svg>
                            <p> កំពុងបញ្ចូល</p>
                        </button>
                    </div>
                </form>


                <!-- <pre>{{ datatoedit }}</pre> -->

            </div>


        </div>
    </div>
</template>

<script>

import { ref, onMounted, watch, handleError } from "vue";

import getCollection from "@/composible/getCollections";


import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useStorage from "@/composible/useStorange";
import { computed } from "vue";
import useDocument from "@/composible/useDocument";
import { push } from "notivue";
import { useRoute } from "vue-router";
import { projectFirestore } from "@/firebase/config";
import { doc, updateDoc } from "@firebase/firestore";
export default {
    emit: ["AddSusccesfully", "UpddateSuccess"],
    components: {
        ckeditor: CKEditor.component
    },
    props: ["datatoedit"],
    setup(props, { emit }) {

        const categoryDescription = ref("");
        const loading = ref(false);
        const productName = ref("")
        const details = ref("")
        const productDetails = ref("")
        const poductId = ref("")
        const selectFile = ref("")
        const preViewBanner = ref([])

        const productFeatures = ref([]);
        const imageURL = ref("")

        const introductVideoLink = ref("")
        const productDetailLink = ref("")
        const introductContent = ref("")

        //introduce image
        const selectIntroFile = ref("")
        const preViewIntroFile = ref("")

        const productBriefDescription = ref("")
        const productBriefImage = ref("")

        const selectProductBriefFile = ref("")
        const preViewProductBriefFile = ref("")


        const preKeyFeatureBenefitFile = ref([])
        const selectKeyFeatureBenefitFile = ref([])

        const keyFeatureBenefitTitle = ref("")

        const keyFeatureBenefitDescription = ref("")

        const preViewTargetBusinessFile = ref([])
        const selectTargetBusinessFeature = ref([])

        const prevHardwareSystemFile = ref([])
        const selectHardwareSystemFeature = ref([])



        const specificationTitle = ref("")
        const specificationDescription = ref("")
        const specificationList = ref([])

        const packageTitle = ref("")
        const packageDescription = ref("")
        const packageList = ref([])

        const hardwareTitle = ref("")


        const keyFeatureBenefitList = ref([])

        const route = useRoute();

        const { documents: product } = getCollection('products')

        const { uploadImage, removeImage } = useStorage();

        const editor = ClassicEditor;

        onMounted(async () => {
            if (props.datatoedit) {
                introductVideoLink.value = props.datatoedit.introductVideoLink
                selectIntroFile.value = props.datatoedit.introImage
                introductContent.value = props.datatoedit.introductContent
                productBriefDescription.value = props.datatoedit.productBriefDescription
                selectProductBriefFile.value = props.datatoedit.productBriefImage
                selectKeyFeatureBenefitFile.value = props.datatoedit.keyFeatureBenefit.image
                keyFeatureBenefitList.value = props.datatoedit.keyFeatureBenefit.content
                selectTargetBusinessFeature.value = props.datatoedit.targetBusiness,
                    specificationTitle.value = props.datatoedit.specification.title
                specificationList.value = props.datatoedit.specification.description
                packageTitle.value = props.datatoedit.package.title
                packageList.value = props.datatoedit.package.description,
                    hardwareTitle.value = props.datatoedit.hardwareSystem.title
                selectHardwareSystemFeature.value = props.datatoedit.hardwareSystem.description

                productDetailLink.value = props.datatoedit.videoLink
            }
        });



        //previewImage feature
        const handleImageProducts = (e) => {
            try {
                const files = Array.from(e.target.files);
                const imagePromises = files.map(file => {
                    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
                        alert("Only PNG and JPEG formats are allowed.");
                        return null;
                    }


                    if (file.size > 5 * 1024 * 1024) {
                        console.error("Image size exceeds 5MB limit.");
                        alert("Image size exceeds 5MB limit.");
                        return null;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    preViewBanner.value.push(previewUrl);

                    const storagePath = `featureImage/${file.name}`;
                    return uploadImage(storagePath, file);
                });

                Promise.all(imagePromises).then(urls => {
                    urls.forEach(url => {
                        if (url) {
                            const newFeature = { image: url };
                            productFeatures.value.push(newFeature);
                        }
                    });
                    preViewBanner.value = [];
                    selectFile.value = null;
                });
            } catch (err) {
                console.log(err);
            }
        };


        //file introduct

        const handleIntroFile = (e) => {
            try {
                const file = e.target.files[0];
                preViewIntroFile.value = URL.createObjectURL(file);
                selectIntroFile.value = file;
            }
            catch (err) {
                console.log(err)
            }
        }



        //remove intro image
        const handleRemoveIntroImage = async() => {
            try {
                if (props.datatoedit?.id) {
                    const isConfirmed = window.confirm("Are you sure you want to remove this image update?");
                    if (isConfirmed) {
                        const productDetailRef = doc(
                            projectFirestore,
                            "products",
                            route.params.id, // Parent document ID
                            "productDetails", // Subcollection
                            props.datatoedit.id // Subcollection document ID
                        );

                        // Update Firestore subcollection document
                        await updateDoc(productDetailRef, {
                            introImage: null,
                        });
                        preViewIntroFile.value = null; 
                        selectIntroFile.value = null; 
                        handleClose();
                        push.success("Image successfully removed from document.");
                    }
                    return; 
                }

                if (selectIntroFile.value) {
                    const isConfirmed = window.confirm("Are you sure you want to remove this image?");
                    if (isConfirmed) {
                        preViewIntroFile.value = null; // Reset preview
                        selectIntroFile.value = null; // Clear selected file
                        console.log("Image removed locally.");
                    }
                    return; 
                }

                console.log("No image found to remove."); 

            }
            catch (err) {
                console.log(err)
            }
        };

        // file product brief image
        const handleProductBriefImage = (e) => {
            try {
                const file = e.target.files[0];
                preViewProductBriefFile.value = URL.createObjectURL(file);
                selectProductBriefFile.value = file;


            }
            catch (err) {
                console.log(err)
            }
        }


        //remove product brief image
        const handleRemoveProductBriefImage = async () => {
            try {
                if (props.datatoedit?.id) {
                    const isConfirmed = window.confirm("Are you sure you want to remove this image update?");
                    if (isConfirmed) {
                        const productDetailRef = doc(
                            projectFirestore,
                            "products",
                            route.params.id, // Parent document ID
                            "productDetails", // Subcollection
                            props.datatoedit.id // Subcollection document ID
                        );

                        // Update Firestore subcollection document
                        await updateDoc(productDetailRef, {
                            productBriefImage: null,
                        });
                        preViewProductBriefFile.value = null; 
                        selectProductBriefFile.value = null; 
                        handleClose();
                        push.success("Image successfully removed from document.");

                    }
                    return; 
                }

                if (selectProductBriefFile.value) {
                    const isConfirmed = window.confirm("Are you sure you want to remove this image?");
                    if (isConfirmed) {
                        preViewProductBriefFile.value = null; // Reset preview
                        selectProductBriefFile.value = null; // Clear selected file
                        console.log("Image removed locally.");
                    }
                    return; 
                }

                console.log("No image found to remove."); 

            } catch (err) {
                console.error("Error removing product brief image:", err);
            }
        };


        //file key feature benifit image
        const handleKeyFeatureImage = (e) => {
            try {
                const files = Array.from(e.target.files);
                const imagePromises = files.map(file => {
                    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
                        alert("Only PNG and JPEG formats are allowed.");
                        return null;
                    }


                    if (file.size > 5 * 1024 * 1024) {
                        console.error("Image size exceeds 5MB limit.");
                        alert("Image size exceeds 5MB limit.");
                        return null;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    preKeyFeatureBenefitFile.value.push(previewUrl);

                    const storagePath = `keyFeatureImage/${file.name}`;
                    return uploadImage(storagePath, file);
                });

                Promise.all(imagePromises).then(urls => {
                    urls.forEach(url => {
                        if (url) {
                            const newFeature = { image: url };
                            selectKeyFeatureBenefitFile.value.push(newFeature);
                        }
                    });
                    // preViewTargetBusinessFile.value = [];
                    // selectTargetBusinessFeature.value = [];
                });
            } catch (err) {
                console.log(err);
            }
        };


        //add to edit
        const handleAddEditFeatureImage = (index) => {
            const newFileInput = document.createElement('input');
            newFileInput.type = 'file';
            newFileInput.accept = 'image/png, image/jpeg';

            // Listen for the user to select a new image
            newFileInput.addEventListener('change', (e) => {
                const file = e.target.files[0];

                if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                    if (file.size > 5 * 1024 * 1024) {
                        alert("Image size exceeds 5MB limit.");
                        return;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    const storagePath = `keyFeatureImage/${file.name}`;

                    // Upload the image and replace the old one
                    uploadImage(storagePath, file).then((url) => {
                        if (url) {
                            selectKeyFeatureBenefitFile.value.splice(index, 1, { image: url });
                        }
                    }).catch(err => {
                        console.error("Error uploading image:", err);
                    });
                } else {
                    alert("Only PNG and JPEG formats are allowed.");
                }
            });

            newFileInput.click();
        };



        //file target business image

        const handleTargetBusiness = (e) => {
            try {
                const files = Array.from(e.target.files);
                const imagePromises = files.map(file => {
                    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
                        alert("Only PNG and JPEG formats are allowed.");
                        return null;
                    }


                    if (file.size > 5 * 1024 * 1024) {
                        console.error("Image size exceeds 5MB limit.");
                        alert("Image size exceeds 5MB limit.");
                        return null;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    preViewTargetBusinessFile.value.push(previewUrl);

                    const storagePath = `targetBussinessImage/${file.name}`;
                    return uploadImage(storagePath, file);
                });

                Promise.all(imagePromises).then(urls => {
                    urls.forEach(url => {
                        if (url) {
                            const newFeature = { image: url };
                            selectTargetBusinessFeature.value.push(newFeature);
                        }
                    });
                    // preViewTargetBusinessFile.value = [];
                    // selectTargetBusinessFeature.value = [];
                });
            } catch (err) {
                console.log(err);
            }
        };

        //add to edit target business
        const handleEditTargetBussiness = (index) => {
            const newFileInput = document.createElement('input');
            newFileInput.type = 'file';
            newFileInput.accept = 'image/png, image/jpeg';

            // Listen for the user to select a new image
            newFileInput.addEventListener('change', (e) => {
                const file = e.target.files[0];

                if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                    if (file.size > 5 * 1024 * 1024) {
                        alert("Image size exceeds 5MB limit.");
                        return;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    const storagePath = `targetBussinessImage/${file.name}`;

                    // Upload the image and replace the old one
                    uploadImage(storagePath, file).then((url) => {
                        if (url) {
                            selectTargetBusinessFeature.value.splice(index, 1, { image: url });
                        }
                    }).catch(err => {
                        console.error("Error uploading image:", err);
                    });
                } else {
                    alert("Only PNG and JPEG formats are allowed.");
                }
            });

            newFileInput.click();
        }



        //file hardware system image
        const handleSelectHardwareFile = (e) => {
            try {
                const files = Array.from(e.target.files);
                const imagePromises = files.map(file => {
                    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
                        alert("Only PNG and JPEG formats are allowed.");
                        return null;
                    }


                    if (file.size > 5 * 1024 * 1024) {
                        console.error("Image size exceeds 5MB limit.");
                        alert("Image size exceeds 5MB limit.");
                        return null;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    prevHardwareSystemFile.value.push(previewUrl);

                    const storagePath = `systemHardware/${file.name}`;
                    return uploadImage(storagePath, file);
                });

                Promise.all(imagePromises).then(urls => {
                    urls.forEach(url => {
                        if (url) {
                            const newFeature = { image: url };
                            selectHardwareSystemFeature.value.push(newFeature);
                        }
                    });
                    // preViewTargetBusinessFile.value = [];
                    // selectTargetBusinessFeature.value = [];
                });
            } catch (err) {
                console.log(err);
            }
        };

        //introductVideoLink

        const isValidIntroDetailLink = computed(() => {
            const videoPatterns = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|.*\.(mp4|webm|ogg|m4v))(.*)?$/i;
            return videoPatterns.test(introductVideoLink.value);
        });

        // Check if the link is a direct video file
        const isDirectIntroDetailLink = computed(() => {
            const directVideoExtensions = /\.(mp4|webm|ogg|m4v)$/i;
            return directVideoExtensions.test(introductVideoLink.value);
        });

        // Generate YouTube embed link for YouTube URLs
        const youtubeEmbedIntroLink = computed(() => {
            if (!isValidIntroDetailLink.value || isDirectIntroDetailLink.value) return "";
            const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/watch\?v=)([a-zA-Z0-9_-]+)/;
            const match = introductVideoLink.value.match(youtubeRegex);
            return match ? `https://www.youtube.com/embed/${match[1]}` : "";
        });



        //video link product details


        // Check if the link is a valid video link
        const isValidproductDetailLink = computed(() => {
            const videoPatterns = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|.*\.(mp4|webm|ogg|m4v))(.*)?$/i;
            return videoPatterns.test(productDetailLink.value);
        });

        // Check if the link is a direct video file
        const isDirectproductDetailLink = computed(() => {
            const directVideoExtensions = /\.(mp4|webm|ogg|m4v)$/i;
            return directVideoExtensions.test(productDetailLink.value);
        });

        // Generate YouTube embed link for YouTube URLs
        const youtubeEmbedLink = computed(() => {
            if (!isValidproductDetailLink.value || isDirectproductDetailLink.value) return "";
            const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/watch\?v=)([a-zA-Z0-9_-]+)/;
            const match = productDetailLink.value.match(youtubeRegex);
            return match ? `https://www.youtube.com/embed/${match[1]}` : "";
        });


        const handleClose = () => {
            emit("close");
        };



        //remove product
        const handleRemoveProduct = async (index) => {
            try {

                if (window.confirm("Are you sure remove?")) {
                    productFeatures.value.splice(index, 1);

                }
            } catch (error) {
                console.error("Error removing feature:", error);
            }
        };

        //remove target business

        const handleRemoveTargetBusiness = async (index) => {
            try {

                if (window.confirm("Are you sure remove?")) {
                    selectTargetBusinessFeature.value.splice(index, 1);

                }
            } catch (error) {
                console.error("Error removing feature:", error);
            }
        };

        //handle remove hardware system
        const handleRemoveHardwareSystem = async (index) => {
            try {

                if (window.confirm("Are you sure remove?")) {
                    selectHardwareSystemFeature.value.splice(index, 1);

                }

            } catch (error) {
                console.error("Error removing feature:", error);
            }
        };


        //edit hardware system

        const handleAddEditHardwareImage = (index) => {
            const newFileInput = document.createElement('input');
            newFileInput.type = 'file';
            newFileInput.accept = 'image/png, image/jpeg';

            // Listen for the user to select a new image
            newFileInput.addEventListener('change', (e) => {
                const file = e.target.files[0];

                if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                    if (file.size > 5 * 1024 * 1024) {
                        alert("Image size exceeds 5MB limit.");
                        return;
                    }

                    const previewUrl = URL.createObjectURL(file);
                    const storagePath = `systemHardware/${file.name}`;

                    // Upload the image and replace the old one
                    uploadImage(storagePath, file).then((url) => {
                        if (url) {
                            selectHardwareSystemFeature.value.splice(index, 1, { image: url });
                        }
                    }).catch(err => {
                        console.error("Error uploading image:", err);
                    });
                } else {
                    alert("Only PNG and JPEG formats are allowed.");
                }
            });

            newFileInput.click();
        }



        //handle remove key feature
        const handleRemoveKeyFeature = async (index) => {
            try {

                if (window.confirm("Are you sure remove?")) {
                    selectKeyFeatureBenefitFile.value.splice(index, 1);
                }

            } catch (error) {
                console.error("Error removing feature:", error);
            }
        };



        //add keybenefit title


        //add row keybenefit
        const addDescriptionRow = () => {
            // Ensure both inputs are not empty
            if (keyFeatureBenefitTitle && keyFeatureBenefitTitle.value && keyFeatureBenefitDescription && keyFeatureBenefitDescription.value) {
                if (keyFeatureBenefitList && Array.isArray(keyFeatureBenefitList.value)) {
                    // Push an object containing both title and description into one list
                    keyFeatureBenefitList.value.push({
                        title: keyFeatureBenefitTitle.value,
                        description: keyFeatureBenefitDescription.value
                    });
                }

                // Clear the input fields after pushing to the list
                keyFeatureBenefitTitle.value = "";
                keyFeatureBenefitDescription.value = "";
            }
        };


        //remove row keybenefit
        const removeDescriptionRow = (index) => {

            if (window.confirm("Are you sure remove?")) {
                keyFeatureBenefitList.value.splice(index, 1);
            }
        }

        //handle edit keybenefit content
        const handleEditContent = (index) => {
            keyFeatureBenefitTitle.value = keyFeatureBenefitList.value[index].title;
            keyFeatureBenefitDescription.value = keyFeatureBenefitList.value[index].description;
            keyFeatureBenefitList.value.splice(index, 1);
        }




        // add row keybenefit

        const addSpacificationList = () => {
            if (specificationDescription.value) {
                specificationList.value.push(specificationDescription.value);
                specificationDescription.value = "";
            }
        }


        // remove row keybenefit

        const removeSpacificationList = (index) => {

            if (window.confirm("Are you sure remove?")) {
                specificationList.value.splice(index, 1);
            }
        }

        //handle edit specification
        const handleEditSpacification = (index) => {
            specificationDescription.value = specificationList.value[index];
            specificationList.value.splice(index, 1);
        }

        //add row pagekage
        const addPackageList = () => {
            if (packageDescription.value) {
                packageList.value.push(packageDescription.value);
                packageDescription.value = "";
            }
        }


        //remove packagelist
        const removePackageList = (index) => {
            if (window.confirm("Are you sure remove?")) {
                packageList.value.splice(index, 1);
            }
        }

        //handle edit package
        const handleEditPackage = (index) => {
            packageDescription.value = packageList.value[index];
            packageList.value.splice(index, 1);
        }


        const handleSubmitProductDetail = async () => {

            const { addDocs } = useDocument("products", productName.value, "productDetails");
            const { updateDocs } = useDocument("products", route.params.id, "productDetails");

            try {


                loading.value = true;
                let productBriefUrl = "";
                let introductUrl = "";



                // Handle banner image upload, retention, or removal
                if (selectProductBriefFile.value && selectProductBriefFile.value !== (props.datatoedit?.productBriefImage || "")) {
                    // Check if banner image exceeds 5MB limit
                    if (selectProductBriefFile.value.size > 1024 * 1024 * 5) {
                        alert("មិនអាចបញ្ចូលរូបភាពលើសពី 5MB បានទេ");
                        loading.value = false;
                        return;
                    }


                    // Upload the new banner image
                    const storagePath = `featureImage/${selectProductBriefFile.value.name}`;
                    productBriefUrl = await uploadImage(storagePath, selectProductBriefFile.value);

                    // Remove the old banner image if it exists
                    if (props.datatoedit?.productBriefImage) {
                        await removeImage(props.datatoedit.productBriefImage);
                    }

                } else if (props.datatoedit?.productBriefImage) {
                    // Retain the existing banner image if no new banner image is selected
                    productBriefUrl = props.datatoedit.productBriefImage;
                } else {
                    // Explicitly set productBriefImageUrl to null to clear the banner image
                    productBriefUrl = props.datatoedit.productBriefImage || null;
                    // productBriefUrl = null;
                }



                // Handle banner image upload, retention, or removal
                if (selectIntroFile.value && selectIntroFile.value !== (props.datatoedit?.introImage || "")) {
                    // Check if banner image exceeds 5MB limit
                    if (selectIntroFile.value.size > 1024 * 1024 * 5) {
                        alert("មិនអាចបញ្ចូលរូបភាពលើសពី 5MB បានទេ");
                        loading.value = false;
                        return;
                    }


                    // Upload the new banner image
                    const storagePath = `introImage/${selectIntroFile.value.name}`;
                    introductUrl = await uploadImage(storagePath, selectIntroFile.value);

                    // Remove the old banner image if it exists
                    if (props.datatoedit?.introImage) {
                        await removeImage(props.datatoedit.introImage);
                    }

                } else if (props.datatoedit?.productBriefImage) {
                    // Retain the existing banner image if no new banner image is selected
                    introductUrl = props.datatoedit.introImage;
                } else {
                    // Explicitly set productBriefImageUrl to null to clear the banner image
                    introductUrl = props.datatoedit.introImage || null;
                    // introductUrl = null;
                   
                }


                const productDetails = {
                    introductVideoLink: introductVideoLink.value,
                    introImage: introductUrl,
                    productDetailLink: productDetailLink.value,
                    introductContent: introductContent.value,
                    productBriefDescription: productBriefDescription.value,
                    productBriefImage: productBriefUrl,
                    keyFeatureBenefit: {
                        content: keyFeatureBenefitList.value,
                        image: selectKeyFeatureBenefitFile.value
                    },
                    targetBusiness: selectTargetBusinessFeature.value,
                    specification: {
                        title: specificationTitle.value,
                        description: specificationList.value
                    },
                    package: {
                        title: packageTitle.value,
                        description: packageList.value
                    },
                    hardwareSystem: {
                        title: hardwareTitle.value,
                        description: selectHardwareSystemFeature.value
                    },
                    videoLink: productDetailLink.value

                }

                if (props.datatoedit) {
                    await updateDocs(props.datatoedit.id, productDetails);
                    push.success("បានកែប្រែទិន្នន័យបានជោគជ័យ");
                    // alert("បានកែប្រែទិន្នន័យបានជោគជ័យ")
                    handleClose();
                    loading.value = false;
                } else {

                    await addDocs(productDetails);
                    loading.value = false;
                    push.success("បានបញ្ចូលទិន្នន័យបានជោគជ័យ");

                    handleClose();
                }



            } catch (err) {
                console.log(err)
            }

            finally {
                loading.value = false;
            }

        }
        return {
            handleClose,
            categoryDescription,
            loading,
            editor,
            product,
            productName,
            details,
            productDetails,

            poductId,
            preViewBanner,
            selectFile,
            handleImageProducts,
            imageURL,
            productFeatures,
            handleRemoveProduct,
            handleSubmitProductDetail,

            //introductVideoLink
            introductVideoLink,
            isValidIntroDetailLink,
            youtubeEmbedIntroLink,

            //for file
            selectIntroFile,
            preViewIntroFile,
            handleIntroFile,
            introductContent,

            //productDetail link
            productDetailLink,
            isValidproductDetailLink,
            youtubeEmbedLink,


            productBriefDescription,
            productBriefImage,

            selectProductBriefFile,
            preViewProductBriefFile,
            handleProductBriefImage,
            handleRemoveKeyFeature,
            keyFeatureBenefitTitle,
            keyFeatureBenefitDescription,

            handleTargetBusiness,
            selectTargetBusinessFeature,
            handleRemoveTargetBusiness,

            handleKeyFeatureImage,
            preKeyFeatureBenefitFile,
            selectKeyFeatureBenefitFile,

            handleSelectHardwareFile,
            prevHardwareSystemFile,
            selectHardwareSystemFeature,
            handleRemoveHardwareSystem,


            specificationTitle,
            specificationDescription,
            packageTitle,
            packageDescription,
            hardwareTitle,

            addDescriptionRow,
            keyFeatureBenefitList,
            removeDescriptionRow,

            specificationList,
            addSpacificationList,
            removeSpacificationList,
            packageList,
            addPackageList,
            removePackageList,


            handleAddEditFeatureImage,
            handleEditContent,
            handleEditTargetBussiness,

            handleEditSpacification,
            handleEditPackage,

            handleAddEditHardwareImage,
            handleRemoveIntroImage,
            handleRemoveProductBriefImage

        };
    },
};
</script>
<style scoped></style>