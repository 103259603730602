<template>
    <div class="flex flex-col items-center justify-center w-full h-full  fixed top-0 right-0 bg-black/20 z-[20]">
        <div v-motion-slide-top
            class="w-[90%] p-5 mx-auto md:w-[80%] lg:w-[50%] xl:w-[30%] bg-white lg:-mt-[35rem] xl:-mt-[35rem] -mt-[35rem] ">
            <div class="w-full mx-auto flex items-center justify-between ">
                <h1 class="text-primery1 text-heading3">

                </h1>
                <button @click="handleClose"
                    class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
                        class="w-8 h-8 text-primery1">
                        <path
                            d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
                    </svg>
                </button>
            </div>
            <div>
                <!-- {{ productDetail }} -->
                <h2 class="text-xl font-bold">តើអ្នកចង់លុប ព័ត៍មានផលិតផលមែនទេ?</h2>
            </div>
            <div class="flex items-center justify-end py-4 gap-2">
                <button
                    class="px-5 p-2 text-white bg-accent  transition-all duration-300 ease-in-out hover:bg-yellow-500"
                    @click="handleClose">
                    បោះបង់
                </button>
                <button v-if="!loading"
                    class="px-5 p-2 text-white bg-primery1 transition-all duration-300 ease-in-out hover:bg-blue-500 hover:text-white"
                    @click="handleDeleteProductDetail(productDetail.id)">
                    លុប
                </button>
                <button v-else
                    class="px-5 p-2 text-white bg-primery1/80 transition-all duration-300 ease-in-out "
                >
                    កំពុងលុប...
                </button>
            </div>
            <!-- <pre>{{ productDetail }}</pre> -->
        </div>
    </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import { onMounted, ref } from "vue";
import { timestamp } from "@/firebase/config";
import { push } from 'notivue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useResetPassword from "@/composible/ForgetPassword";
import useDocument from "@/composible/useDocument";
import useStorage from "@/composible/useStorange";
import { useRoute } from "vue-router";

export default {
    emit: ["AddSusccesfully", "UpddateSuccess"],
    components: {
        ckeditor: CKEditor.component
    },
    props: ["datatoedit", "productDetail"],
    setup(props, { emit }) {

        const editor = ClassicEditor; // Set the CKEditor editor type

        const route = useRoute()

        const Question = ref("");
        const Answer = ref("");
        const loading = ref(false);
        const { addDocs, updateDocs } = useCollection("FAQ");

        const { deleteDocs } = useDocument("products", route.params.id, "productDetails");
        const { removeImage } = useStorage()

        onMounted(() => {
            if (props.datatoedit) {
                Question.value = props.datatoedit.question;
                Answer.value = props.datatoedit.answer;
            }
        });

        const handleSubmit = async () => {
            loading.value = true;
            const productData = {
                question: Question.value,
                answer: Answer.value,
                createdAt: timestamp(),
            };
            if (props.datatoedit) {
                const upadateSuccess = await updateDocs(
                    props.datatoedit?.id,
                    productData
                );
                if (upadateSuccess) {
                    emit("UpddateSuccess");
                }
                push.success("Update successfull!")
            } else {
                const success = await addDocs(productData);

                if (success) {
                    emit("AddSusccesfully");
                }
            }
            handleClose();
        };

        const handleClose = () => {
            emit("close");
            Answer.value = "";
            Question.value = "";
        };


        const handleDeleteProductDetail = async (productDetailsId) => {
            try {
                loading.value = true;
                if (productDetailsId) {
                    await deleteDocs(productDetailsId)
                    push.success("បានលុបដោយជោគជ័យ")
                    handleClose()
                }

                loading.value = false

            }
            catch (err) {
                console.log(err)
            }
            finally {
                loading.value = false
            }

        };



        return {
            handleClose,
            handleSubmit,
            Question,
            Answer,
            loading,
            editor,
            handleDeleteProductDetail
        };
    },
};
</script>