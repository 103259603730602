<template>
  <div
    class="w-full h-full bg-black/20 fixed lg:top-[8rem] top-0 left-0 xl:hidden right-0 select-none text_navbar z-[999]">
    <div style="box-shadow: 0 20px 9px -22px rgba(0,0,0,0.2) inset;"
      class="text-start relative font-NotoSansKhmer flex flex-col w-[380px] h-screen items-start  md:w-[350px] pt-[20px] bg-background">

      <!-- Close button -->
      <div
        class="flex justify-end cursor-pointer absolute top-0 right-0 bg-gradient-to-r from-purple-600 to-indigo-700 p-1 ">
        <svg v-motion-fade @click="handleClose" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
          fill="currentColor" class="w-8 h-8 text_navbar text-white">
          <path
            d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
        </svg>
      </div>

      <router-link @click="handleClose" to="/"
        class="text-heading4 border-t py-3 mt-5 w-full border-gray-300 font-bold px-4 hover:text-primery1/70 duration-300">
        ទំព័រដើម
      </router-link>

      <!-- Categories Dropdown -->
      <div class="w-full">
        <div @click="isDropdownSide = !isDropdownSide"
          class="text-heading4 border-t w-full py-3 border-gray-300 font-bold px-4 hover:text-primery1/70 duration-300 flex justify-between items-center">
          <p class="text-heading4 font-bold duration-300  ">សេវាកម្ម</p>
          <div>
            <svg v-if="!isDropdownSide" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-chevron-down">
              <path d="m6 9 6 6 6-6" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-chevron-up">
              <path d="m18 15-6-6-6 6" />
            </svg>
          </div>
        </div>

        <!-- Show categories -->
        <div
          :class="isDropdownSide ? 'max-h-[900px] opacity-100 visible block transition-all duration-500 ease-out' : 'max-h-0 opacity-0 invisible overflow-hidden transition-all duration-500 ease-out'">
          <div class="select-none lg:hidden bg-white text">
            <div class="w-full mb-5">
              <div v-for="category in categories" :key="category.name" class="pl-5">
                <div @click="fetchProductsByCategory(category.id)" class="cursor-pointer py-2 px-4 w-full">
                  <div class="flex justify-between items-center hover:text-primery1">
                    <p class="text-lg font-[500]">{{ category.label }}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="size-[20px] mr-[2px]">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                  </div>

                  <!-- Display products for the category -->
                  <div v-if="category.id === selectedCategory">
                    <ul v-if="products.length > 0" class="bg-white p-2 h-full overflow-hidden overflow-y-auto">
                      <li v-for="(product, index) in products" :key="index"
                        class="py-2 px-3 hover:text-primery1 cursor-pointer">

                        <!-- Check if category is not one of the restricted categories -->
                        <a v-if="category.label !== 'អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ' && category.label !== 'រចនាគេហទំព័រ'"
                          :href="`/systemdev/${product.id}`" class="line-clamp-1 lg:text-[16px] xl:text-[18px]">
                          {{ product.name }}
                        </a>

                        <!-- If it's one of the restricted categories, do not link -->
                        <span v-else class="line-clamp-1 lg:text-[16px] xl:text-[18px] cursor-not-allowed">
                          {{ product.name }}
                        </span>
                      </li>
                    </ul>
                  </div>


                </div>
              </div>

              <div class="pl-9 mt-2">
                <router-link @click="handleClose" class="text-lg font-[500] hover:text-primery1"
                  to="/productsandservices">មើលទាំងអស់</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- showDropdownCompany -->

      <router-link @click="handleClose" to="/viewAllproduct"
        class="text-heading4 w-full  border-b border-t border-gray-300 font-bold px-4 py-3 hover:text-primery1/70 duration-300">
        ឧបករណ៍
      </router-link>



      <div class="w-full">
        <div @click="showDropdownCompany = !showDropdownCompany"
          class="text-heading4  w-full py-3 border-gray-300 font-bold px-4 hover:text-primery1/70 duration-300 flex justify-between items-center">
          <p class="text-heading4 font-bold duration-300  ">អំពីក្រុមហ៊ុន</p>
          <div>
            <svg v-if="!showDropdownCompany" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" class="lucide lucide-chevron-down">
              <path d="m6 9 6 6 6-6" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="lucide lucide-chevron-up">
              <path d="m18 15-6-6-6 6" />
            </svg>
          </div>
        </div>

        <!-- Show categories -->
        <div
          :class="showDropdownCompany ? 'max-h-[600px] opacity-100 visible block transition-all duration-500 ease-out' : 'max-h-0 opacity-0 invisible overflow-hidden transition-all duration-500 ease-out'">
          <div class="select-none lg:hidden bg-white text">
            <div class="w-full mb-5">
              <div class="space-y-3 flex flex-col text-lg  lg:text-[14px] xl:text-[17px] mt-2 pl-10">
                <router-link @click="handleClose" to="/aboutus"
                  class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500]  lg:text-[16px] xl:text-[18px] items-center justify-between">អំពីពួកយើង</router-link>
                <router-link @click="handleClose" to="/aboutus#history"
                  class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500] lg:text-[16px] xl:text-[18px] items-center justify-between">
                  បុព្វហេតុ និង​ ប្រវត្តនៃកាបង្កើត
                </router-link>

                <router-link @click="handleClose" to="/visionMissionValue"
                  class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500]  lg:text-[16px] xl:text-[18px] items-center justify-between">ចក្ខុវិស័យ-បេសកម្ម-គុណតម្លៃ</router-link>
                <router-link @click="handleClose" to="/actionTeamwork"
                  class="cursor-pointer font-NotoSansKhmer hover:text-primery1 flex gap-2 font-[500]  lg:text-[16px] xl:text-[18px] items-center justify-between">សកម្មភាពក្រុមការងារ</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <router-link @click="handleClose" to="/contact"
        class="text-heading4 w-full border-t border-b border-gray-300 font-bold px-4 py-3  hover:text-primery1/70 duration-300">
        ទំនាក់ទំនង
      </router-link>
    </div>
  </div>
</template>

<script>
import { getCollectionQuery } from "@/composible/getCollection";
import { ref } from "vue";

export default {
  setup(props, { emit }) {
    const isDropdownSide = ref(false);
    const products = ref([]);
    const selectedCategory = ref("");
    const showDropdownCompany = ref(false)
    const categories = ref([
      { id: "1nseQAZYyuZzwobRlI5u", name: "Business Management System", label: "អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ" },
      { id: "Qpu0VxyD4MELRLwTRRUs", name: "Web Design", label: "រចនាគេហទំព័រ" },
      { id: "ra1yTG7WvRtntQ9utxru", name: "System Development", label: "រៀបចំប្រព័ន្ធគ្រប់គ្រងសម្រាប់អាជីវកម្ម" },
    ]);

    const handleClose = () => {
      emit("close");
    };

    const fetchProductsByCategory = async (categoryName) => {
      if (categoryName === selectedCategory.value) {
        selectedCategory.value = null;
      } else {
        selectedCategory.value = categoryName;
        try {
          await getCollectionQuery(
            "products",
            [],
            (data) => {
              products.value = data.filter((product) => product.cid === categoryName);
            },
            true
          );

        } catch (error) {
          console.error("Error fetching products:", error.message);
        }

      }
    };


    const handleReload = (id) => {
      setTimeout(() => {
        window.location.reload();
      }, 0);
      handleClose();
    };

    return { handleClose, isDropdownSide, handleReload, fetchProductsByCategory, categories, products, selectedCategory, showDropdownCompany };
  },
};
</script>
