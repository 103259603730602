<template>
  <div class="select-none hidden lg:block text_navbar">
    <div class="grid grid-cols-2 p-5" @mouseleave="scheduleClear" @mouseenter="cancelClear">
      <!-- Categories Section -->
      <div
        class="space-y-3 flex flex-col lg:text-[14px] xl:text-[17px] bg-white xl:w-[350px] h-full border-l border-t border-b border-r p-6">
        <div class="space-y-3 flex flex-col lg:text-[14px] xl:text-[17px] mt-0">
          <div v-for="category in categories" :key="category.id"
            class="cursor-pointer font-poppins font-[500] flex gap-2 lg:text-[16px] xl:text-[18px] items-center justify-between"
            :class="activeCategory === category.id ? 'text-primery1' : 'hover:text-primery1'"
            @mouseenter="setActiveCategory(category.id, category.name)">
            <p class="font-NotoSansKhmer">{{ category.name }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="size-[18px] font-bold">
              <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </div>

          <router-link to="/productsandservices"
            class="cursor-pointer hover:text-primery1 lg:text-[16px] xl:text-[18px]">
            មើលទាំងអស់
          </router-link>


        </div>
      </div>

      <!-- Subcategories Section -->
      <div>
        <ul v-if="products.length > 0" class="bg-white lg:w-[370px] xl:w-[580px] border-b border-r border-t p-2 h-full"
          @mouseenter="cancelClear" @mouseleave="scheduleClear">
          <li v-for="(product, index) in products" :key="index" class="py-2 px-3 hover:bg-gray-100 cursor-pointer">
            <!-- Check category name -->
            <template
              v-if="activeCategoryName === 'អភិវឌ្ឍន៍ប្រព័ន្ធគ្រប់គ្រងទិន្នន័យ' || activeCategoryName === 'រចនាគេហទំព័រ'">
              <span class="text-gray-500 line-clamp-1 lg:text-[15px] xl:text-[17px] font-[500]">
                {{ product.name }}
              </span>
            </template>
            <template v-else>
              <a :href="`/systemdev/${product.id}`"
                class="hover:text-primery1 line-clamp-1 lg:text-[15px] xl:text-[17px] font-[500]">
                {{ product.name }}
              </a>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import getCollection from "@/composible/getCollections";
import { where } from "@firebase/firestore";

export default {
  setup() {
    const products = ref([]);
    const categories = ref([]);
    const activeCategory = ref("");
    const activeCategoryName = ref("");
    let clearTimeoutRef = null;

    // Fetch categories from Firestore
    const { documents: categoryDocuments } = getCollection("categories");
    watch(categoryDocuments, () => {
      categories.value = categoryDocuments.value || [];
    });

    // Fetch products based on the active category
    const fetchProductsByCategory = async (categoryType) => {
      try {
        const { documents: productDocs } = getCollection(
          "products",
          where("cid", "==", categoryType)
        );

        watch(productDocs, () => {
          products.value = productDocs.value || [];
        });

        activeCategory.value = categoryType;
        const category = categories.value?.find((cat) => cat.id === categoryType);
        activeCategoryName.value = category ? category.name : "";

        console.log("activeCategoryName:", activeCategoryName.value); // Debugging line

      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    // Handle category hover
    const setActiveCategory = (categoryId, categoryName) => {
      if (activeCategory.value !== categoryId) {
        fetchProductsByCategory(categoryId, categoryName);
      }
    };

    // Clear products when mouse leaves
    const scheduleClear = () => {
      clearTimeoutRef = setTimeout(() => {
        products.value = [];
        fetchProductsByCategory("1nseQAZYyuZzwobRlI5u"); // Default category
      }, 50);
    };

    // Cancel clearing of products
    const cancelClear = () => {
      if (clearTimeoutRef) {
        clearTimeout(clearTimeoutRef);
        clearTimeoutRef = null;
      }
    };

    // Initialize default category
    watch(categoryDocuments, () => {
      if (categories.value.length > 0) {
        fetchProductsByCategory("1nseQAZYyuZzwobRlI5u");
      }
    });

    return {
      products,
      categories,
      activeCategory,
      activeCategoryName,
      setActiveCategory,
      scheduleClear,
      cancelClear,
    };
  },
};
</script>
