<template>

  <div class="flex flex-col items-center justify-center w-full h-screen fixed top-0 right-0 bg-black/20 z-[20]">
    <div v-motion-slide-top class="w-full overflow-y-auto overflow-x-auto   h-screen">
      <div v-motion-slide-top
        class="m-5 md:m-6  lg:w-[80%] xl:w-[50%] lg:mx-auto  bg-white shadow-sm  p-3 overflow-auto space-y-4">

        <div class="w-full mx-auto flex  items-center justify-between mb-4">
          <h1 class="text-primery1 text-heading3">
            {{ datatoedit ? "កែប្រែផលិតផល" : "បន្ថែមផលិតផល" }}
          </h1>
          <button @click="handleClose"
            class="w-10 h-10 rounded-full focus:border-primery1 focus:border flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"
              class="w-8 h-8 text-primery1">
              <path
                d="M5.28 4.22a.75.75 0 0 0-1.06 1.06L6.94 8l-2.72 2.72a.75.75 0 1 0 1.06 1.06L8 9.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L9.06 8l2.72-2.72a.75.75 0 0 0-1.06-1.06L8 6.94 5.28 4.22Z" />
            </svg>
          </button>
        </div>
        <!-- <div class="relative w-full overflow-x-auto bg-gray-50 border h-[150px]">
          <table v-if="productFeatures.length > 0">
            <tr>
              <td class="text-center">ល.រ</td>
              <td>លក្ខណៈ</td>
              <td>លំអិត</td>
              <td>រូបភាព</td>
              <th class="text-center">សកម្មភាព</th>
            </tr>
            <tr v-for="(feature, index) in productFeatures" :key="index">
              <td class="text-center w-[50px]">{{ index + 1 }}</td>
              <td>{{ feature.title }}</td>
              <td class="w-[700px]">
                <p class="prose" v-html="feature.details"></p>
              </td>
              <td class="w-[80px]">
                <img width="50px" :src="feature.image" />
              </td>
              <td>
                <div class="flex w-full h-full gap-3 items-center justify-center">
                  <button @click="editFeature(index, feature)"
                    class="w-[36px] flex items-center justify-center h-[36px] bg-accent rounded-[6px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                      class="w-6 h-6 text-white">
                      <path
                        d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path
                        d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                  </button>
                  <button @click="handleRemoveFeature(index)"
                    class="w-[36px] flex items-center justify-center h-[36px] bg-red-500 rounded-[6px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                      class="w-6 h-6 text-white">
                      <path fill-rule="evenodd"
                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                        clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <div v-else
            class="relative w-[600px] mx-auto flex items-center justify-center text-primery1 font-NotoSansKhmer text-body h-full overflow-x-auto sm:rounded-lg">
            <h2 class="text-center">មិនទាន់មាន</h2>
          </div>
        </div> -->

        <div class=" gap-4 overflow-x-auto w-full">
          <form @submit.prevent="handleSubmit" class=" space-y-2 items-center justify-center border p-4">
            <div class="w-full flex flex-wrap md:flex-nowrap gap-2">
              <div class="w-full space-y-2">
                <label class="text-body text-textbody"> ឈ្មោះផលិតផល:* </label>

                <input required v-model="productName" type="text" class="border p-2 w-full hover:border-primery1"
                  placeholder="ឈ្មោះផលិតផល" />
              </div>

              <div class="w-full">
                <div class="w-full space-y-2">
                  <label class="text-body text-textbody">
                    ការបង្ហាញផលិតផល: *
                  </label>
                  <div class="">
                    <select required class="border p-2 w-full hover:border-primery1" v-model="productDisplay">
                      <option disabled value="">ជ្រើសរើស</option>
                      <option required v-for="productItem in productDisplayStanding" :value="productItem"
                        :key="productItem">
                        {{ productItem }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full space-y-2">
              <label class="text-body text-textbody">
                ជ្រើសរើសប្រភេទផលិតផល: *
              </label>
              <div class="">
                <select required class="border p-2 w-full hover:border-primery1" v-model="itemsType">
                  <option disabled value="">ជ្រើសរើសប្រភេទ</option>
                  <option v-for="productType in category" :value="productType.id" :key="productType.id">
                    {{ productType.name }}
                  </option>
                </select>
              </div>
              <div>
                <label class="text-body text-textbody"> លំអិតពីផលិតផល </label>
                <!-- <textarea rows="5" type="text" v-model="productDesscript" placeholder="លំអិត"
                  class="border p-2 w-full hover:border-primery1"></textarea> -->
                <div id="app">
                  <ckeditor :editor="editor" v-model="productDesscript" :config="editorConfig" />
                </div>


              </div>
            </div>

            <div class="flex gap-4">
              <div class="w-full space-y-2 relative">
                <label class="text-body text-textbody"> រូបភាពផលិតផល:* </label>

                <div class="hover:border-primery1 border-[1px]   border-dashed relative overflow-auto h-[165px] w-[300px]">
                  <div v-if="!pre_image"
                    class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointe w-full">
                    <img :src="productImage || 'https://fakeimg.pl/446x265/'" alt="">
                  </div>
                  <div v-else>
                    <img :src="pre_image" alt="Product Image" class="" />
                  </div>
                  <input @change="handleFileChangeProductImage" type="file"
                    class="opacity-0 absolute w-full cursor-pointer z-50" />
                </div>


                <!-- 
                <button type="button" @click="handleClearImage(datatoedit.image)"
                  class="bg-red-500 p-2 hover:bg-red-400 absolute top-7 right-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-x text-white ">
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button> -->
              </div>

              <!-- <div class="w-full space-y-2 relative">
                <label class="text-body text-textbody"> ផ្ទាំងបង្ហាញផលិតផល:* </label>

                <div class="hover:border-primery1 border-[1px]   border-dashed relative overflow-auto h-[165px] w-full">
                  <div v-if="!preViewBanner"
                    class="text-center text-lebeltext text-heading4 overflow-auto cursor-pointe w-full">
                    <img :src="selectFileBanner || 'https://fakeimg.pl/446x265/'" alt="">
                  </div>
                  <div v-else>
                    <img :src="preViewBanner" alt="Product Image" class="" />
                  </div>

                  <input @change="handleBannerProduct" type="file"
                    class="opacity-0 absolute w-full cursor-pointer z-50" />
                </div>

                <button type="button" @click="handleClearImageBanner"
                  class="bg-red-500 p-2 hover:bg-red-400 absolute top-7 right-0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="lucide lucide-x text-white ">
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                  </svg>
                </button>
              </div> -->

            </div>

            <div>


              <div class="w-full space-y-2">
                <label class="text-body text-textbody"> វីដេអូអំពីផលិតផល:* </label>

                <input v-model="videoLink" id="videoLink" type="text" class="border p-2 w-full hover:border-primery1"
                  placeholder="វីដេអូអំពីផលិតផល" />
              </div>

              <!-- Video Preview -->
              <div v-if="isValidVideoLink" class="mt-4">
                <h4>វីដេអូពិពណ៌នា:</h4>

                <video v-if="isDirectVideoLink" :src="videoLink" controls class="w-full max-w-md"></video>

                <iframe v-else :src="youtubeEmbedLink" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen
                  class="w-full max-w-md aspect-video"></iframe>
              </div>

              <!-- Validation Message -->
              <p v-else-if="videoLink" class="text-red-500 mt-2">
                សូមបញ្ចូលលីងវីដេអូឲបានត្រឹមត្រូវ
              </p>

            </div>

            <div v-if="loading == false" class="w-auto flex justify-end mx-auto">
              <button
                class="px-10 p-3 bg-primery1 transition-all duration-300 ease-in-out hover:bg-blue-500 text-white mt-2">
                {{ datatoedit ? "កែប្រែ" : "បន្ថែមថ្មី" }}
              </button>
            </div>
            <div v-else class="w-auto flex justify-end mx-auto">
              <button class="px-5 p-3 bg-primery1 items-center  flex  text-white">
                <svg aria-hidden="true" role="status" class="inline w-6 h-6 me-3 text-white animate-spin"
                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor" />
                </svg>
                កំពុងបញ្ចូល
              </button>
            </div>
          </form>


          <!-- <div class="relative w-1/2 overflow-x-auto">
            <div class="w-full border h-full p-4  space-y-2">
              <form @submit.prevent="handleAddFeature" class="space-y-2">
                <label class="text-body text-textbody">
                  ចំណងជើងលក្ខណៈផលិតផល:*
                </label>

                <div class="flex w-full gap-2">
                  <input required v-model="productFeaturesTitle" type="text"
                    class="w-full border p-2 pl-2 hover:border-primery1" placeholder="ចំណងជើងលក្ខណៈផលិតផល" />
                </div>
                <label class="text-body text-textbody"> រូបភាព:* </label>
                <div class="flex gap-2">
                  <div class="hover:border-primery1 border-[1px] border-dashed relative">
                    <div v-if="!feature_image" class="text-center text-lebeltext text-heading4">
                      <img :src="'https://fakeimg.pl/660x400/'" alt="">
                    </div>
                    <div v-else>
                      <img :src="feature_image" alt="Product Image" class="w-[400px]  object-contain" />
                    </div>


                    <input required @change="handleFileChange" type="file" accept="image/*"
                      class="opacity-0 absolute w-full h-full cursor-pointer top-0" />
                  </div>


                </div>
                <img :src="img" class="w-20" alt="">

                <label class="text-body text-textbody">
                  លក្ខណៈផលិតផលពីលំអិត:*
                </label>


                <div id="app">
                  <ckeditor :editor="editor" v-model="productFeatueDetails" />
                </div>

                <div class="w-auto flex justify-end mx-auto">
                  <button
                    class="bg-primery1 p-3 px-10  hover:bg-blue-500  transition-all duration-300 ease-in-out text-white">
                    {{ datatoedit ? "កែប្រែ" : "បញ្ចូល" }}
                  </button>
                </div>

              </form>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCollection from "@/composible/useCollection";
import useStorage from "@/composible/useStorange";
import { push } from "notivue";
import { ref, onMounted } from "vue";
import { projectFirestore, projectStorage, timestamp } from "@/firebase/config";
import { getCollectionQuery } from "@/composible/getCollection";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { computed } from "vue";
import { doc, updateDoc, FieldValue, onSnapshot } from "@firebase/firestore";



export default {
  emit: ["AddSusccesfully", "UpddateSuccess"],
  components: {
    ckeditor: CKEditor.component
  },
  props: ["datatoedit"],
  setup(props, { emit }) {
    const category = ref([]);
    const itemsType = ref("");
    const imageURL = ref("")
    const editor = ClassicEditor;
    const ispending = ref(false);
    const productName = ref("");
    const productDesscript = ref("");
    const selectVideo = ref(null)
    const file_video = ref(null)
    const productImage = ref(null);
    const productFeatures = ref([]);
    const productFeaturesTitle = ref("");
    const productFeatueDetails = ref("");
    const img = ref(null);
    const pre_image = ref(null)
    const feature_image = ref("")
    const productDisplay = ref("");
    let operationSuccess;
    const loading = ref(false);
    const currentComponent = ref("");
    const { uploadImage, removeImage } = useStorage();
    const { addDocs, updateDocs } = useCollection("products");
    const productDisplayStanding = ref(["outstanding", "normal"]);
    const selectFileBanner = ref("")
    const preViewBanner = ref("")


    const videoLink = ref("")

    onMounted(() => {
      getDataCategory();

      try {
        if (props.datatoedit) {
          productName.value = props.datatoedit.name;
          productFeatures.value = props.datatoedit.feature;
          itemsType.value = props.datatoedit.type;
          productDesscript.value = props.datatoedit.descritpts;
          productDisplay.value = props.datatoedit.productdisplay;
          productImage.value = props.datatoedit.image;

          // Set the bannerImage in the file input if it exists
          if (props.datatoedit.bannerImage) {
            selectFileBanner.value = props.datatoedit.bannerImage;
          } else {
            // Clear the file input if no bannerImage exists
            selectFileBanner.value = null;
          }

          // Set video link if available
          videoLink.value = props.datatoedit.videoLink;
        }
      } catch (err) {
        console.log(err);
      }
    });



    //for link video

    // Check if the link is a valid video link
    const isValidVideoLink = computed(() => {
      const videoPatterns = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|.*\.(mp4|webm|ogg|m4v))(.*)?$/i;
      return videoPatterns.test(videoLink.value);
    });

    // Check if the link is a direct video file
    const isDirectVideoLink = computed(() => {
      const directVideoExtensions = /\.(mp4|webm|ogg|m4v)$/i;
      return directVideoExtensions.test(videoLink.value);
    });

    // Generate YouTube embed link for YouTube URLs
    const youtubeEmbedLink = computed(() => {
      if (!isValidVideoLink.value || isDirectVideoLink.value) return "";
      const youtubeRegex = /(?:youtu\.be\/|youtube\.com\/watch\?v=)([a-zA-Z0-9_-]+)/;
      const match = videoLink.value.match(youtubeRegex);
      return match ? `https://www.youtube.com/embed/${match[1]}` : "";
    });




    //get category
    const getDataCategory = async () => {
      try {
        await getCollectionQuery(
          "categories",
          [],
          (data) => {
            category.value = data;
          },
          true
        );
      } catch (error) {
        console.error(error.message);
      }
    };

    //upload video
    const handleChangeVideo = (e) => {
      try {
        const file = e.target.files[0];
        selectVideo.value = URL.createObjectURL(file)
        file_video.value = file;
        console.log(selectVideo.value);
      }
      catch (err) {
        console.log(err)
      }

    }

    //upload file product
    const handleFileChangeProductImage = (event) => {
      try {
        const file = event.target.files[0];
        if (!file) {
          console.error("No file selected.");
          return;
        }
        const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
        const extension = file.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(extension)) {
          console.error("Only jpg, png, svg, and jpeg files are allowed.");
          alert("Only jpg, png, svg, and jpeg files are allowed.")
          return;
        }
        pre_image.value = URL.createObjectURL(file)
        productImage.value = file;
      }
      catch (err) {
        console.log(err)
      }
    };


    //upload file feature
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      const allowedExtensions = ["jpg", "png", "svg", "jpeg"];
      if (!file) {
        console.error("No file selected.");
        return;
      }
      const extension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(extension)) {
        console.error("Only jpg, png, svg, and jpeg files are allowed.");
        return;
      }
      feature_image.value = URL.createObjectURL(file)
      img.value = file;
    };


    //change banner product

    const handleBannerProduct = (e) => {
      try {
        const file = e.target.files[0]
        preViewBanner.value = URL.createObjectURL(file)
        selectFileBanner.value = file

      }
      catch (err) {
        console.log(err)
      }
    }

    //handle submit


    const handleSubmit = async () => {
      loading.value = true;
      try {
        let imageURL = null;
        let videoURL = null;
        let imageBannerUrl = null;

        // Handle product image upload and retention
        if (productImage.value && productImage.value !== (props.datatoedit?.image || "")) {
          // Check if product image exceeds 5MB limit
          if (productImage.value.size > 1024 * 1024 * 5) {
            alert("មិនអាចបញ្ចូលរូបភាពលើសពី 5MB បានទេ");
            loading.value = false;
            return;
          }

          // Upload the new product image
          const storagePath = `product/${productImage.value.name}`;
          imageURL = await uploadImage(storagePath, productImage.value);

          // Remove the old image if it exists
          if (props.datatoedit?.image) {
            await removeImage(props.datatoedit.image);
          }

        } else if (props.datatoedit?.image) {
          // Retain the existing image if no new image is selected
          imageURL = props.datatoedit.image;
        } else {
          // Set image URL to an empty string if no image exists
          imageURL = '';
        }

        // Handle banner image upload, retention, or removal
        if (selectFileBanner.value && selectFileBanner.value !== (props.datatoedit?.bannerImage || "")) {
          // Check if banner image exceeds 5MB limit
          if (selectFileBanner.value.size > 1024 * 1024 * 5) {
            alert("មិនអាចបញ្ចូលរូបភាពលើសពី 5MB បានទេ");
            loading.value = false;
            return;
          }

          // Upload the new banner image
          const storagePath = `productBanner/${selectFileBanner.value.name}`;
          imageBannerUrl = await uploadImage(storagePath, selectFileBanner.value);

          // Remove the old banner image if it exists
          if (props.datatoedit?.bannerImage) {
            await removeImage(props.datatoedit.bannerImage);
          }

        } else if (props.datatoedit?.bannerImage) {
          // Retain the existing banner image if no new banner image is selected
          imageBannerUrl = props.datatoedit.bannerImage;
        } else {
          // Explicitly set bannerImageUrl to null to clear the banner image
          imageBannerUrl = null;
        }

        // Prepare the product data
        const productData = {
          cid: itemsType.value,
          name: productName.value,
          feature: productFeatures.value,
          type: itemsType.value,
          descritpts: productDesscript.value,
          productdisplay: productDisplay.value,
          image: imageURL,           // Image URL (new or existing)
          bannerImage: imageBannerUrl, // Banner URL (new, retained, or null)
          video: videoURL,
          videoLink: videoLink.value,
          createdAt: timestamp(),
        };

        // Update or add product
        let operationSuccess;
        if (props.datatoedit) {
          operationSuccess = await updateDocs(props.datatoedit.id, productData);
        } else {
          operationSuccess = await addDocs(productData);
          push.success("បានរក្សាទុក");
          ispending.value = true;
        }

        // Notify success and close the form
        if (operationSuccess) {
          emit(props.datatoedit ? "updateSuccessfully" : "addSuccessfully");
          push.success("បានរក្សាទុក");
          handleClose();
          console.log("Product operation successful", productData);
        }
      } catch (error) {
        console.error("Error performing product operation:", error);
      } finally {
        loading.value = false;
        ispending.value = false;
      }
    };




    //add feature
    const handleAddFeature = async () => {
      try {
        if (img && img.value) {
          if (img.value.size > 1024 * 1024) {
            console.error("Image size exceeds 1MB limit.");
            alert("Image size exceeds 1MB limit.");
            return;
          }
          // Upload the image and get the URL
          const storagePath = `feature/${img.value.name}`;
          imageURL.value = await uploadImage(storagePath, img.value);

        } else {
          // If no new image is selected, retain the existing image URL
          imageURL.value = props.datatoedit?.image || null;
        }
        // Create a new feature object
        const newFeature = {
          title: productFeaturesTitle.value,
          details: productFeatueDetails.value,
          image: imageURL.value,
        };

        // Add the new feature to the list
        productFeatures.value.push(newFeature);
        // Reset the form fields
        productFeaturesTitle.value = "";
        productFeatueDetails.value = "";
        img.value = null;
        feature_image.value = null;

        console.log("New feature added:", newFeature);
      } catch (error) {
        console.error("Error adding feature:", error);
      }
    };


    //edit feature
    const editFeature = (index, item) => {
      if (index >= 0 && index < productFeatures.value.length) {
        // Store the old image
        const oldImage = productFeatures.value[index].image;
        console.log(oldImage);

        // Remove the item at the specified index
        productFeatures.value.splice(index, 1);

        // Update the text values with the properties of the item being edited
        productFeaturesTitle.value = item.title;
        productFeatueDetails.value = item.details;

        // Check if the new item has an image; if not, use the old image
        if (item.image) {
          img.value = item.image;

        } else {
          img.value = oldImage;
          alert("Old Image")
        }
      }
    };

    //handle close
    const handleClose = () => {
      currentComponent.value = "";
      emit("close");
    };

    //handle remove feature
    const handleRemoveFeature = async (index, productId) => {
      productFeatures.value.splice(index, 1);
      try {
        await updateDocs("products", productId, {
          productFeatures: productFeatures.value,
        });
        console.log("Feature removed successfully from Firebase");
      } catch (error) {
        console.error("Error removing feature from Firebase:", error);
      }
    };

    const handleClearImage = async () => {

      console.log("Clearing image for product ID:", props.datatoedit.id);
      try {

        const docRef = doc(projectFirestore, 'products', props.datatoedit.id);

        // await updateDoc(docRef, {
        //   image: "" 
        // });

        await updateDocs("products", props.datatoedit.id, {
          image: ""
        })

        console.log("Image removed successfully from Firestore!");

        pre_image.value = "https://fakeimg.pl/446x265/";

      } catch (err) {
        console.error("Error removing image from Firestore:", err);
      }

    }


    //for reset image only one​ field

    const handleClearImageBanner = async () => {


      try {
        const bannerImage = props.datatoedit?.bannerImage;

        if (!bannerImage) {
          console.error("No banner image to remove");
          return;
        }
        const productRef = doc(projectFirestore, "products", props.datatoedit.id);

        await updateDoc(productRef, {
          bannerImage: null,
        });

        // await removeImage(props.datatoedit?.bannerImage)

        console.log("bannerImage field successfully set to null in Firestore");

        // Add a real-time listener to update the UI when the document is updated
        onSnapshot(productRef, (docSnapshot) => {
          const updatedData = docSnapshot.data();
          console.log("Updated Firestore document data:", updatedData);

          if (updatedData?.bannerImage === null) {
            preViewBanner.src = "https://fakeimg.pl/446x265/";
            selectFileBanner.value = null;
          }
          push.success("បានលុបរូបភាព");
          handleClose()
        });

      } catch (error) {
        console.error("Error setting bannerImage field to null in Firestore:", error);
      }
    };


    return {
      handleClose,
      handleAddFeature,
      handleFileChange,
      productFeatueDetails,
      productFeaturesTitle,
      productFeatures,
      productDisplay,
      productName,
      img,
      itemsType,
      handleSubmit,
      currentComponent,
      productDisplayStanding,
      handleRemoveFeature,
      editFeature,
      push,
      productDesscript,
      ispending,
      handleFileChangeProductImage,
      productImage,
      loading,
      category,
      pre_image,
      feature_image,
      editor,
      selectVideo,
      file_video,
      handleChangeVideo,
      videoLink,

      isValidVideoLink,
      isDirectVideoLink,
      youtubeEmbedLink,
      handleBannerProduct,
      preViewBanner,
      selectFileBanner,


      handleClearImageBanner,
      handleClearImage
    };
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #510192b9;
  border-radius: 10px;
  margin-left: 20px;
  transition: 3s;
}

::-webkit-scrollbar-thumb:hover {
  display: block;
  transition: 0.5s;
}

table,
tr,
th,
td {
  border: 1px solid lightgray;
  border-collapse: collapse;


}
</style>
